import "./Booking.css";

export const Booking = () => {
  return (
    <div className="Booking">
      <div className="container">
        <div className="booking-info mx-auto w-75 p-2 rounded">
          <div className="mb-2">
            <h1>Booking Information</h1>
            <p className="rounded p-2 mx-auto">
              I am currently accepting new clients through Vagaro. Please use
              the "Book Now" button below, or click{" "}
              <a href="https://www.vagaro.com/marigoldmoonbodywork/book-now">
                here
              </a>
              , to schedule an appointment with me.
            </p>
          </div>
          <div className="payment mb-2">
            <h4>Payment Methods Accepted</h4>
            <p className="rounded p-2 mx-auto">
              Visa and MasterCard, Discover, Debit card, Cash
              <br />
              At this time, I do not accept insurance.
            </p>
          </div>
          <div className="parking mb-2">
            <h4>Parking</h4>
            <p className="rounded p-2 mx-auto">
              We are located at{" "}
              <a href="https://www.google.com/maps/place/10828+Gravelly+Lake+Dr+SW+Ste+202,+Lakewood,+WA+98499/@47.1584404,-122.5238618,17z/">
                10828 Gravelly Lake Dr SW Ste 202, Lakewood, WA
              </a>
              <br />
              All sides of the building have free parking available.
            </p>
          </div>
          <div className="cancellation-policy">
            <h4>Appointment Cancellation Policy</h4>
            <p className="rounded p-2 mx-auto">
              If you need to reschedule or cancel your appointment please call
              or text (253) 888-3467 atleast 24 hrs before your appointment time
              to avoid a fee, within 24 hrs a 50% cancelation fee will be
              charged. No call/no shows will be charged full price.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
