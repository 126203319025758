import "./App.css";
import { Home } from "./Home";
import { About } from "./About";
import { Booking } from "./Booking";
import { NotFoundPage } from "./NotFoundPage";
import { Contact } from "./Contact";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavBar } from "./NavBar";
import footer from "./Footer.png";

export default function App() {
  return (
    <div className="App">
      <NavBar />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/contact" element={<Contact />} />
          <Route element={<NotFoundPage />} />
        </Routes>
      </Router>
      <div className="container">
        <img
          src={footer}
          className="w-100 fixed-bottom footer-img"
          alt="Marigold Moon Bodywork footer"
        />
        <div className="row fixed-bottom">
          <a
            type="button"
            href="https://www.vagaro.com/marigoldmoonbodywork/book-now"
            className="btn btn-primary shadow p-3 rounded fixed-bottom w-50 start-50 translate-middle"
          >
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
}
