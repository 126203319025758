import React from "react";
import logo from "./MMBLogo.png";
import "./Home.css";

export const Home = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="mb-3">
          <img
            src={logo}
            alt="Marigold Moon Bodywork by Samantha Barrows, LMT in Washington State"
            className="w-25"
          />
          <h3 className="p-2">Intuitive Massage Therapy and Bodywork</h3>
          <h5>Located in Lakewood, WA</h5>
        </div>
        <div className="row mx-auto home-desc rounded w-75">
          <h4 className="p-2">Our Philosophy & Approach</h4>
          <p className="body mx-auto rounded p-3 mb-3">
            Every body tells a story and the tension we carry with us are
            indications of where we have been, the important parts of our lives,
            and sometimes even the things we are passionate about. Tension in
            your shoulders after a long work day, low back pain from sitting,
            driving, pregnancy, injury, pain in your arms from working with your
            hands, a persistent pain in your legs/glutes/feet from sports, a
            longing to de-stress and escape- are all signs that your body is
            calling for self care. Now is the time to prioritize self care, to
            take care of your body so that you can keep doing the things you
            love! Marigold Moon uses several modalities and techniques to tailor
            every session. Swedish, deep tissue, triggerpoint, cupping,
            aromatherapy, and craniosacral are used to achieve my clients' goals
            for each session.
          </p>
        </div>
        <div className="services-offered mt-3 mx-auto rounded w-75">
          <h4 className="p-2 pb-1">Services Offered</h4>
          <ul className="rounded mx-auto w-75 p-3 m-3">
            <li className="">Swedish massage</li>
            <li>Deep tissue</li>
            <li>Triggerpoint</li>
            <li>Cupping</li>
            <li>Aromatherapy</li>
            <li>Craniosacral</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
