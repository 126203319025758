import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_p1r9gr2",
        "template_lug8tbt",
        form.current,
        "N7m0xQKu55PBKmhOa"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Thank you for your inquiry! We'll get back to you shortly");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="contact-form">
      <h1>Contact</h1>
      <p className="w-75 mx-auto rounded p-1">
        Please complete the form below, or text/call{" "}
        <a href="tel:2538883467" alt="253-888-3467">
          (253) 888-3467
        </a>{" "}
        if you have any questions.
        <br />
        Thank you!
      </p>
      <div className="input-group mx-auto row w-75 mt-2">
        <label>Name</label>
        <input type="text" name="user_name" placeholder="Name" />
      </div>
      <div className="input-group mx-auto row w-75 mt-2">
        <label>Email Address</label>
        <input type="email" name="user_email" placeholder="Email Address" />
      </div>
      <div className="input-group mx-auto row w-75 mt-2">
        <label>Phone Number</label>
        <input type="tel" name="user_phone" placeholder="Phone Number" />
      </div>
      <div className="input-group mx-auto row w-75 mt-2">
        <label>Message</label>
        <textarea name="message" placeholder="Write your message here" />
      </div>
      <div className="input-group mx-auto row w-75 mt-2">
        <input
          type="submit"
          value="Send"
          className="btn btn-primary w-50 mx-auto"
        />
      </div>
    </form>
  );
};
