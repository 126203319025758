import "./About.css";
import websiteImg from "./WebsiteImg.png";

export const About = () => {
  return (
    <div className="About">
      <div className="container row mx-auto">
        <div className="primary rounded about">
          <img
            src={websiteImg}
            alt="Samantha Barrows License Massage Therapist in Lakewood, WA"
            className="mx-auto w-75 p-2 rounded img-thumbnail"
          />
          <div className="bio p-2 w-75 mx-auto rounded mb-5">
            <h1>Samantha Barrows</h1>
            <h4>Licensed Massage Therapist (LMT) in Washington State</h4>
            <h5>Massage Therapist License # MA61161355</h5>
            <p className="p-2 rounded w-100 mx-auto mb-3">
              My passion lies in helping others create a path for healing. I am
              so grateful to be a part of my clients' journeys through pain
              management, stress reduction, pregnancy, and various injuries. I
              love working with people from all walks of life and tailoring our
              massage sessions to fit their needs. My style would be described
              as flowy, relaxing, and deep where it needs to be. I use a blend
              of swedish, deep tissue, and triggerpoint to meet my clients'
              goals. Wherever your journey for healing starts, I'm excited to
              begin it with you!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
